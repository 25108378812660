import { kebabCase } from "lodash";
import { graphql, useStaticQuery } from "gatsby";
import Link from "gatsby-link";
import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { ButtonLink, IconBook, IconCaret, IconClipboard, IconPhoneChecklist, IconSearch, Small } from "@upsolve/ui";

import Logo from "../../../static/images/upsolve-logo-white.svg";
import { MyUpsolveLink } from "../Links/MyUpsolveLink";
import trackComponentAction from "../analytics/trackComponentAction";
import UpsolveSearchBar from "../search/UpsolveSearchBar";
import { CTATarget, getCTARenderedCopy, getCTATrackingTarget, getCTAURL } from "../CTA/getCTAData";
import { $TSFixMe } from "@upsolve/shared/dist/types";
import { NavPopUp } from "./NavPopUp";
import HelloBar from "./HelloBar";
import MenuIcon from "../../../static/images/icon-menu.svg";

import { CTATrackingTarget } from "../analytics/track";
import { DebtAdvisorModal } from "../DebtAdvisor/DebtAdvisorModal";
import { createPortal } from "react-dom";

function getPath() {
  return typeof window === "undefined" ? "" : window.location.pathname;
}

const trackingProps = {
  componentName: "Header",
  componentVersion: "8",
};

type Props = {
  trackingPageContext: $TSFixMe;
  ctaTarget: CTATarget;
  allowMobileFooter?: boolean;
};
/**
 * Header
 * - v0: Navbar with heavy link list under learning center
 * - v1: Navbar with search and simpler cta: 'Join Upsolve'
 * - v2: Navbar with fixed mobile footer and fixed desktop header navbar w/ bigger CTA
 * - v3: Popup message for app
 * - v4: Small popup activity shown above mobile footer
 * - v5: Added TIMES 100 inventions logo
 * - v6: Learn links point to category pages, not high value articles
 * - v8: added sign in button
 * @component
 * @version 8
 */
const Header = (props: Props) => {
  const { allowMobileFooter = true } = props;
  const [isMobileMenuShowing, setIsMobileMenuShowing] = useState(false);
  const [isLearnPanelOpen, setLearnPanelOpen] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [showDebtTriageModal, setShowDebtTriageModal] = useState(false);
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      learnArticleTags: allContentfulLearnArticleTag(
        filter: { hasDirectoryPage: { eq: true } }
        sort: { fields: [label], order: ASC }
      ) {
        nodes {
          descriptionLong {
            descriptionLong
          }
          hasDirectoryPage
          label
          value
          type
        }
      }
    }
  `);
  const learnArticleTags = data.learnArticleTags.nodes.filter((tag) => tag.type === "category");

  // this button always opens the triage
  const onClickGetDebtHelpButton = () => {
    trackComponentAction({
      ...trackingProps,
      actionId: `header.CTAButton.${getCTATrackingTarget(props.ctaTarget)}`,
      actionMethod: "click",
      pageContext: props.trackingPageContext,
    });
    setShowDebtTriageModal(true);
  };
  const onClickSignInButton = () => {
    trackComponentAction({
      ...trackingProps,
      actionId: `header.signInButton.${CTATrackingTarget.LOGIN}`,
      actionMethod: "click",
      pageContext: props.trackingPageContext,
    });
  };
  //this button does the action for the cta target, and only opens the triage if that's the target
  const onClickMobileFooterCTA = () => {
    trackComponentAction({
      ...trackingProps,
      actionId: `header.CTAButton.${getCTATrackingTarget(props.ctaTarget)}`,
      actionMethod: "click",
      pageContext: props.trackingPageContext,
    });
    if (props.ctaTarget === CTATarget.DEBT_TRIAGE) setShowDebtTriageModal(true);
  };

  // RENDER
  return (
    <div style={{ position: "relative" }}>
      {/* This div holds space for the the fixed position navbar, so that the nav doesn't cover up the top of the page */}
      <NavBarStaticPlaceholder />
      <NavBar>
        <div className="hellobar-container">
          <StudentLoansCTAHelloBar />
        </div>
        <div className="nav">
          <div className="nav__lead">
            <span role="button" aria-label="Toggle Menu">
              <Link to="/">
                Upsolve
                <Logo />
              </Link>
            </span>
            <Small>a nonprofit</Small>
          </div>
          <nav className={`nav__links `}>
            <div>
              <Link aria-busy="false" to="/bankruptcy">
                File Bankruptcy
              </Link>
            </div>
            <div>
              <Link aria-busy="false" to="/learn/transparency/">
                How It's Free
              </Link>
            </div>
            <div>
              <Link aria-busy="false" to="/reviews">
                Member Reviews
              </Link>
            </div>
            <div onMouseEnter={() => setLearnPanelOpen(true)} onMouseLeave={() => setLearnPanelOpen(false)}>
              <Link aria-busy="false" to="/learn/">
                Learn&nbsp;
                <span className="caret">
                  <IconCaret />
                </span>
              </Link>
            </div>
          </nav>

          <div className="nav__ctas">
            <span
              className="nav__ctas__search"
              onClick={(ev) => {
                if (!getPath().includes("/search")) setSearchOpen(true);
              }}
            >
              <IconSearch color="white" />
            </span>
            <ButtonLink onClick={onClickSignInButton} href={"https://my.upsolve.org/login"} inverted>
              Sign In
            </ButtonLink>
            <GetDebtHelpButton onClickGetDebtHelpButton={onClickGetDebtHelpButton} />
          </div>
          <div className="nav__cta__mobile">
            <span
              className="nav__ctas__search"
              onClick={(ev) => {
                if (!getPath().includes("/search")) setSearchOpen(true);
              }}
            >
              <IconSearch color="white" />
            </span>
            <MenuIcon onClick={() => setIsMobileMenuShowing(!isMobileMenuShowing)} />
          </div>
        </div>
        {isSearchOpen && (
          <UpsolveSearchBar
            autoFocus={true}
            onClose={() => setSearchOpen(false)}
            onSearch={() => setSearchOpen(false)}
            position="navbar"
          />
        )}
      </NavBar>
      <LearnCategoryMenu
        className={`${isLearnPanelOpen ? "show" : ""}`}
        onMouseEnter={() => setLearnPanelOpen(true)}
        onMouseLeave={() => setLearnPanelOpen(false)}
      >
        <ul>
          {learnArticleTags.map((tag: { label: string; value: string }) => (
            <li key={tag.label}>
              <Link
                aria-busy="false"
                to={`/learn/category/${kebabCase(tag.value)}`}
                onClick={() => {
                  setLearnPanelOpen(false);
                }}
              >
                {tag.label}
              </Link>
            </li>
          ))}
        </ul>
      </LearnCategoryMenu>

      {allowMobileFooter !== false && (
        <Fragment>
          <MobileFooterNav>
            <Link
              className={
                getPath().includes("/learn/") && !getPath().includes("/learn/how-to-file-bankruptcy/") ? "active" : ""
              }
              onClick={(ev) => {
                if (getPath().includes("/learn/") && !getPath().includes("how-to-file-bankruptcy")) {
                  ev.preventDefault();
                }
              }}
              to="/learn/"
            >
              <div>
                <IconBook />
                Free Articles
              </div>
            </Link>
            <MyUpsolveLink href={getCTAURL(props.ctaTarget)} onClick={onClickMobileFooterCTA}>
              <div className="checklist">
                <IconPhoneChecklist />
                {getCTARenderedCopy(props.ctaTarget).mobileFooterText}
              </div>
            </MyUpsolveLink>
            <Link
              className={getPath().includes("/learn/how-to-file-bankruptcy/") ? "active" : ""}
              to="/learn/how-to-file-bankruptcy/"
            >
              <div>
                <IconClipboard />
                Filing Guide
              </div>
            </Link>
          </MobileFooterNav>

          <NavPopUp trackingPageContext={props.trackingPageContext} target={props.ctaTarget} />
        </Fragment>
      )}
      {showDebtTriageModal &&
        createPortal(
          <DebtAdvisorModal
            trackingPageContext={props.trackingPageContext}
            onHide={() => setShowDebtTriageModal(false)}
          />,
          document.body
        )}
      {isMobileMenuShowing && (
        <MobileMenu onClose={() => setIsMobileMenuShowing(false)} onClickSignInButton={onClickSignInButton} />
      )}
    </div>
  );
};

const StyledMobileMenu = styled.nav`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  top: 0px;
  .menu {
    display: flex;
    z-index: 999;
    width: 70%;
    height: 100%;
    padding: 30px;
    font-size: 18px;
    justify-content: space-between;
    flex: 2;
    background-color: ${(props) => props.theme.colors.gray[300]};
    opacity: 1;
    animation: menu 0.1s linear;

    .links {
      padding-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      a {
        color: white !important;
      }
      flex: 1;
      text-align: right;
    }
    .close-button-container {
      display: flex;
      justify-content: flex-end;
      color: ${(props) => props.theme.colors.gray[800]};
      .close-button {
        font-size: 24px;
      }
    }
    @keyframes menu {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translate(0%);
      }
    }
  }

  .outside-menu {
    flex: 1.5;
  }
`;
const MobileMenu = ({ onClose, onClickSignInButton }: { onClose: () => void; onClickSignInButton: () => void }) => {
  return (
    <StyledMobileMenu>
      <div className="outside-menu" onClick={onClose} />
      <div className="menu">
        <div className="close-button-container">
          <div className="close-button" onClick={onClose}>
            X
          </div>
        </div>
        <div className="links">
          <Link aria-busy="false" to="/bankruptcy">
            File Bankruptcy
          </Link>
          <Link aria-busy="false" to="/learn/transparency/">
            How It's Free
          </Link>
          <Link aria-busy="false" to="/reviews">
            Member Reviews
          </Link>
          <Link aria-busy="false" to="/learn/">
            Learn
          </Link>
          <a onClick={onClickSignInButton} aria-busy="false" href="https://my.upsolve.org/login">
            Sign In
          </a>
        </div>
      </div>
    </StyledMobileMenu>
  );
};
const StudentLoansCTAHelloBar = () => (
  <HelloBar
    helloBarText={
      <span>
        Ready to say goodbye to student loan debt for good?&nbsp;
        <b>Learn More</b>
      </span>
    }
    helloBarUrl="/learn/bankruptcy-eliminate-student-debt/"
    helloBarTrackingActionId="CTA.ARTICLE_STUDENT_LOANS"
  />
);

const TRANSITION_TIMER = "250ms";

const NavBarStaticPlaceholder = styled.div`
  height: 54px;
  width: 100%;
`;

const NavBar = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 999;
  position: fixed;
  top: 0px;
  .nav {
    background: ${(props) => props.theme.colors.brand[500]};
    box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.025);
    display: flex;
    justify-content: space-between;
  }
  a {
    color: ${(props) => props.theme.colors.white[900]};
    &:hover {
      color: ${(props) => props.theme.colors.brand[600]};
      path {
        fill: ${(props) => props.theme.colors.brand[600]};
      }
    }
    font-size: 14px;
    button {
      font-weight: 700;
    }
  }

  .nav__lead,
  .nav__links,
  .nav__ctas {
    flex-grow: 1;
    height: 54px;
  }

  .nav__lead {
    max-width: 150px;
    display: inline-flex;
    align-items: center;
    margin-left: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    small {
      font-size: 10px;
      color: white;
      padding-left: 4px;
    }
    svg {
      display: block;
      height: 32px;
      width: 140px;
      margin-top: -2px;
      @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
        height: 28px;
        width: 120px;
        margin-top: 0;
      }
    }
    span > a {
      display: flex;
      font-size: 0;
    }
    @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
      margin-left: 0.9em;
    }
  }
  .nav__links {
    display: flex;
    .caret > svg {
      height: 8px;
      width: 8px;
      path {
        fill: ${(props) => props.theme.colors.white[900]};
      }
    }

    // Desktop (Wide)
    @media (min-width: ${(props) => props.theme.breakpoints[750]}) {
      flex-grow: 1;
      justify-content: center;
      & > div {
        display: flex;
        align-items: center;
        & > a {
          padding: 0.75em;
          font-weight: 500;
        }
      }
    }
    // Mobile (Slim)
    @media (max-width: ${(props) => props.theme.breakpoints[750]}) {
      position: fixed;
      overflow-y: scroll;
      width: 100vw;
      height: 90vh;
      z-index: 998;
      transform: translateY(100vh);
      padding: 0 0 3em;
      &.show {
        transform: translateY(10vh);
      }
      & > div {
        padding: 1em;
        &:first-of-type {
          margin-top: 1em;
        }
        &:last-of-type {
          margin-bottom: 2.5em;
        }
        & > a {
          font-size: 18px;
          color: ${(props) => props.theme.colors.white[900]};
        }
      }
    }
  }
  .nav__cta__mobile {
    display: none;
    align-items: center;
    justify-content: center;
    margin-right: 0.5em;

    @media (max-width: ${(props) => props.theme.breakpoints[750]}) {
      display: flex;
    }
    svg {
      width: 50px;
      height: auto;
      path {
        fill: white;
      }
    }
  }
  .nav__ctas {
    display: flex;
    flex-grow: 0;
    gap: 8px;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: ${(props) => props.theme.breakpoints[750]}) {
      display: none;
    }
    & > * {
      margin-right: 0.5em;
    }
    & > a {
      padding-left: 6px;
      padding-right: 6px;
      color: ${(props) => props.theme.colors.white[900]};
      font-weight: 500;
      &:last-child {
        margin-right: 1.25em;
      }
      &:hover {
        cursor: pointer;
        color: ${(props) => props.theme.colors.brand[600]};
      }
    }
  }
  .nav__ctas__search {
    height: 36px;
    width: 36px;
    margin: 4px 0;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: white;
    color: white;
    cursor: pointer;
    &:hover {
      path {
        fill: ${(props) => props.theme.colors.brand[600]};
      }
    }
    svg {
      height: 32px;
      min-height: 32px;
      position: relative;
      top: -1px;
    }
  }
  & > a {
    @media (max-width: ${(props) => props.theme.breakpoints[750]}) {
      display: none;
    }
  }

  // Handle transparent background options
  transition: background ${TRANSITION_TIMER}, box-shadow ${TRANSITION_TIMER};
  .nav__lead svg path,
  .nav__links .caret svg > path,
  .nav__ctas__search svg path,
  .nav__links > div > a,
  div.nav__ctas > a {
    transition: fill ${TRANSITION_TIMER}, color ${TRANSITION_TIMER}, border-color ${TRANSITION_TIMER};
  }
`;

const LearnCategoryMenu = styled.div`
  display: none;
  /* TODO: do a pass on these links */
  // Desktop (Wide)
  @media (min-width: ${(props) => props.theme.breakpoints[750]}) {
    width: 100vw;
    position: fixed;
    z-index: 998;
    left: 0;
    right: 0;
    top: 54px;
    background: ${(props) => props.theme.colors.brand[500]};
    box-shadow: 0 5px 15px -5px rgba(0, 0, 25, 0.2);
    padding: 0.75em 2em;
    &.show {
      &,
      ul {
        display: grid;
        list-style-type: none;
        margin: 0;
      }
      ul,
      li {
        grid-gap: 6px;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
      }
      li {
        text-align: center;
        border-radius: 4px;
        background: ${(props) => props.theme.colors.white[900]};
        border: 1px solid ${(props) => props.theme.colors.white[500]};
        display: flex;
        a {
          width: 100%;
          text-align: center;
        }
      }
    }
    a {
      font-size: 13px;
    }
    div {
      margin: 1em 0;
    }
    ul {
      padding: 0 0.5em 0 1em;
      list-style-type: disc;
      flex: 1;
    }
    li {
      margin-bottom: 0.2em;
      color: ${(props) => props.theme.colors.brand[500]};
    }
  }
  // Mobile (Slim)
  @media (max-width: ${(props) => props.theme.breakpoints[750]}) {
    div {
      margin: 1em 0;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      &:first-of-type {
        margin-top: 1em;
      }
      &:last-of-type {
        margin-bottom: 1em;
      }
    }
    li {
      margin-bottom: 0.65em;
      list-style-type: disc;
      color: ${(props) => props.theme.colors.brand[500]};
      margin-left: 1.25em;
      a {
        font-size: 15px;
      }
    }
  }
`;
// TODO: Move to another file
const MobileFooterNav = styled.div`
  display: none;
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  right: 0;
  height: 82px;
  max-height: 82px;
  background: white;
  box-shadow: 0 0 32px 12px rgba(0, 0, 55, 0.15);
  @media (max-width: ${(props) => props.theme.breakpoints[750]}) {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 1.5em;
  }
  @media (max-width: 360px) {
    padding: 0 0.25em;
  }
  & > a {
    margin: 0 6px;
    padding: 6px 0 3px;
    border-bottom: 3px solid ${(props) => props.theme.colors.gray[900]};
    &.active {
      div {
        color: ${(props) => props.theme.colors.brand[500]};
      }
      svg path {
        fill: ${(props) => props.theme.colors.brand[500]};
      }
      border-bottom: 3px solid ${(props) => props.theme.colors.brand[500]};
    }
  }
  & > a,
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    color: ${(props) => props.theme.colors.gray[500]};
    svg {
      height: 26px;
      path {
        fill: ${(props) => props.theme.colors.gray[500]};
      }
    }
  }
  .checklist {
    white-space: nowrap;
    svg {
      height: 37px;
      width: 100%;
    }
  }
`;

const GetDebtHelpButton = ({ onClickGetDebtHelpButton }: { onClickGetDebtHelpButton: () => void }) => {
  return <StyledButton onClick={onClickGetDebtHelpButton}>Get Debt Help</StyledButton>;
};
const StyledButton = styled.button`
  display: flex;
  width: 166.667px;
  height: 40px;
  padding: 6.667px 10.667px;
  justify-content: center;
  align-items: center;
  gap: 5.333px;
  border-radius: 34.667px;
  border: 0.667px solid #d0d5dd;
  background: white;
  color: ${(props) => props.theme.colors.brand[500]};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 100% */
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.colors.brand[600]};
  }
  @media screen and (max-width: 940px) {
    width: 180px;
    height: 40px;
    padding: 10px 16px;
    gap: 8px;
    border-radius: 31px;
    font-size: 16px;
    line-height: 20px; /* 125% */
    margin-right: 0.9em;
  }
`;

export default Header;
