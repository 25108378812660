import { H3, theme } from "@upsolve/ui";
import React from "react";
import styled from "styled-components";
import ThumbsUp from "./ThumbsUp.svg";
import ThumbsDown from "./ThumbsDown.svg";
import QuestionMark from "./QuestionMark.svg";
import { TOptionInfo } from "./ResultsComparison";
import { formatMonths } from "./formatMonths";

type TStatusQuoInfo = {
  interest: number;
  totalPayment: number;
  monthlyPayment: number;
  monthsInRepayment: number;
};

export const DetailCard = (props: {
  optionInfo: TOptionInfo;
  unsecuredDebtEstimate: number;
  availableMonthlyFunds: number;
  statusQuoInfo: TStatusQuoInfo;
  onClickBack: () => void;
}) => {
  return (
    <StyledDetailCardContainer>
      <StyledDetailCard isEligible={props.optionInfo.isEligible}>
        <div>
          <div className="back" onClick={props.onClickBack}>
            Back
          </div>
        </div>
        <div>
          <StyledDetailCardThumbContainer>
            {props.optionInfo.isEligible ? (
              <ThumbsUp />
            ) : props.optionInfo.isEligible !== false ? (
              <QuestionMark />
            ) : (
              <ThumbsDown />
            )}
          </StyledDetailCardThumbContainer>
          <div className="title_section section">
            <H3>{props.optionInfo.title}</H3>
            <div className="description">{props.optionInfo.description}</div>
            {
              <i className={`${props.optionInfo.isEligible ? "green" : "gray"}`}>
                {props.optionInfo.isEligible ? (
                  <div className="actions_section">
                    <div className="button-container">{props.optionInfo.actions}</div>
                  </div>
                ) : (
                  props.optionInfo.notEligibleMessage
                )}
              </i>
            }
          </div>
        </div>
        {props.optionInfo.isEligible !== false && (
          <div className="summary_section section">
            <div className="section-title">Summary</div>
            {/* TODO: use the same component as ResultCard */}
            {props.optionInfo.savings > 0 && (
              <div>
                Savings: <b>${props.optionInfo.savings.toLocaleString()} </b>
              </div>
            )}
            <div>
              {props.optionInfo.debtTimelineBreakdown.fieldName}:{" "}
              <b>{props.optionInfo.debtTimelineBreakdown.duration}</b>
            </div>
            <div>
              Time to apply: <b>{props.optionInfo.timeToApply}</b>
            </div>
            {/* TODO: make this a hover */}
            {props.optionInfo.debtTimelineBreakdown.details && <i>{props.optionInfo.debtTimelineBreakdown.details}</i>}
            {/* <div>
              Success Rate: <b>{props.optionInfo.successRate}%</b>
            </div> */}
          </div>
        )}

        <div className="good_for_section section">
          <b className="section-title">Who is it good for?</b>
          <div>{props.optionInfo.goodForStatement}</div>
        </div>
        <div className="affordability_section section">
          <b className="section-title">
            Can you afford it?{" "}
            {props.optionInfo.canAfford ? "👍" : props.optionInfo.canAfford === undefined ? <></> : "👎"}
          </b>
          <div>{props.optionInfo.canAffordMessage}</div>
        </div>

        {props.optionInfo.isEligible !== false && (
          <div className="savings_section section">
            <b className="section-title">Savings breakdown</b>
            <div className="estimated_debt">
              <b>Without {props.optionInfo.title}</b>
              <br />
              <div className="info-row">
                <div>Principle</div>
                <div>${props.unsecuredDebtEstimate.toLocaleString()}</div>
              </div>

              {/* TODO: move this message above the calculation, then fill in with default values of $99,000+ */}
              {props.statusQuoInfo.totalPayment === 0 ? (
                <div>
                  Your available monthly funds of ${props.availableMonthlyFunds.toLocaleString()} probably cannot cover
                  the interest on this debt. This means you may not be able to pay it off.
                </div>
              ) : (
                <>
                  <div className="info-row">
                    <div>+ estimated interest at 25%</div>
                    <div className="payment">+ ${Math.round(props.statusQuoInfo.interest).toLocaleString()}</div>
                  </div>
                  <div className="info-row">
                    <div>= estimated total payment</div>
                    <div className="red payment">
                      = ${Math.round(props.statusQuoInfo.totalPayment).toLocaleString()}
                    </div>
                  </div>
                  <div>
                    <hr />
                  </div>
                  <div className="info-row">
                    <div>Estimated timeline</div>
                    <div className="red payment">{formatMonths(props.statusQuoInfo.monthsInRepayment)}</div>
                  </div>
                </>
              )}
            </div>
            <div className="estimated_debt">
              <b>With {props.optionInfo.title}</b>
              <br />
              <div className="info-row">
                <div>Principle</div>
                <div className="payment">${props.unsecuredDebtEstimate.toLocaleString()}</div>
              </div>

              {props.optionInfo.savingsBreakdown.map((savingsBreakdownRow) => (
                <div className="info-row">
                  <div>{savingsBreakdownRow.item}</div>
                  <div className="payment">{savingsBreakdownRow.value}</div>
                </div>
              ))}
              <div className="info-row">
                <div>= estimated total payment</div>
                <div className="green payment">= ${Math.round(props.optionInfo.totalPayment).toLocaleString()}</div>
              </div>
              <div>
                <hr />
              </div>
              <div className="info-row">
                <div>{props.optionInfo.debtTimelineBreakdown.fieldName}</div>
                <div className="green payment">{props.optionInfo.debtTimelineBreakdown.duration}</div>
              </div>
            </div>
          </div>
        )}
        <div className="actions_section">
          <div className="button-container-bottom">{props.optionInfo.actions}</div>
        </div>
      </StyledDetailCard>
    </StyledDetailCardContainer>
  );
};
const StyledDetailCardContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: auto;
`;
const StyledDetailCard = styled.div<{ isEligible: boolean | undefined }>`
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 1;
  padding: 20px;
  max-width: 600px;
  gap: 24px;
  .back {
    position: fixed;
    background: ${theme.colors.white[500]};
    top: 44px;
    font-size: 14px;
    padding: 1px 4px;
    border-radius: 8px;
    border: 1px solid ${theme.colors.white[100]};
    cursor: pointer;
    z-index: 99;
    &:hover {
      background: ${theme.colors.white[300]};
    }
  }
  .section {
    border: 2px solid ${theme.colors.white[300]};
    border-radius: 8px;
    padding: 30px;
    background-color: ${(props) => (props.isEligible !== false ? "white" : "unset")};
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .section-title {
      color: ${theme.colors.brand[600]};
      font-size: 18px;
      font-weight: 700;
    }
  }
  .title_section {
    border: 2px solid ${(props) => (props.isEligible ? theme.colors.brand[600] : theme.colors.white[300])};
    border-radius: 8px;
    padding-top: 40px;
    padding-bottom: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 30px;
    .description {
      font-size: 20px;
    }
  }
  .summary_section {
  }
  .savings_section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    .estimated_debt {
      display: flex;
      flex-direction: column;
    }
    .payment {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .good_for_section {
  }
  .affordability_section {
  }
  .actions_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    .button-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .button-container-bottom {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 60px;
    }
  }

  .red {
    color: ${theme.colors.red[300]};
  }
  .green {
    color: ${theme.colors.green[300]};
  }
  .gray {
    color: ${theme.colors.gray[500]};
  }
  .info-row {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledDetailCardThumbContainer = styled.div`
  position: relative;
  top: 38px;
`;
