import styled from "styled-components";
import { ProgressBarWrapper } from "./ProgressBar";
import { Modal, ModalBody, ModalHeader } from "./Modal";
import { P, TypographyCSS } from "@upsolve/ui";
// Iteration of filer/ScreenerModal
export const TriageModal = styled(Modal)`
  width: 100%;
  max-width: 600px;
  min-height: 480px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  // Modal slide-in animation
  position: relative;
  @keyframes modalSlideIn {
    0% {
      bottom: -2000px;
    }
    100% {
      bottom: 0;
    }
  }
  animation: modalSlideIn 350ms forwards ease-out;
`;

export const TriageModalHeader = styled(ModalHeader)`
  display: flex;
  justify-content: flex-start;
  padding-left: 2em;
  padding-right: 2em;
  span:first-of-type {
    display: flex;
    align-items: center;
    div {
      padding: 0;
    }
    small {
      padding-left: 1em;
    }
  }
  ${ProgressBarWrapper} {
    margin: 0 -2em -1em;
  }
  @media (max-width: ${(p) => p.theme.breakpoints[500]}) {
    justify-content: center;
    padding-left: 0.75em;
    padding-right: 0.75em;
    span:first-of-type {
      flex-direction: column;
      small {
        padding-top: 0.5em;
      }
    }
  }
`;

//TODO: DEPRECATE
export const OldTriageModalBody = styled(ModalBody)`
  padding: 2em;
  min-height: 350px;
  input:focus,
  select:focus {
    box-shadow: 0 0 5px ${(props) => props.theme.colors.brand["700"]};
  }
  & > div {
    margin: auto;
    width: 100%;
  }
  @media (max-width: ${(p) => p.theme.breakpoints[500]}) {
    flex-grow: 1;
    max-height: initial;
    min-height: initial;
    padding: 0.75em;
  }

  // Content Nodes Styling
  form,
  .content-node__input,
  button,
  input,
  select {
    width: 100%;
  }
  form .content-node__row {
    height: 100%;
    gap: 12px;
    margin-top: 12px;
    & > button {
      max-height: 100%;
    }
  }
  ul {
    list-style-type: disc;
    margin-left: 10px;
  }

  div:has(> form) {
    overflow-y: auto;
  }
`;

export const TriageModalBody = styled(ModalBody)`
  padding: 2em;
  min-height: 350px;
  @media (max-width: ${(p) => p.theme.breakpoints[500]}) {
    flex-grow: 1;
    max-height: initial;
    min-height: initial;
    padding: 0.75em;
  }

  justify-content: center;

  // content
  input:focus,
  select:focus {
    box-shadow: 0 0 5px ${(props) => props.theme.colors.brand["700"]};
  }
  form,
  button,
  input,
  select {
    width: 100%;
  }
`;

export const TriageModalContent = styled.div`
  width: 100%;
`;

export const QuestionHeading = styled.p`
  font-family: "Castoro";
  font-size: 32px;
  line-height: 42px;
  font-weight: 400;
  white-space: pre-wrap;
  ${TypographyCSS}
`;
